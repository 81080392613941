<template>
    <div>
        <div
            class="text-lg font-bold relative group"
            @mouseenter="mouseOver"
            @mouseleave="mouseLeave"
            @click="handleClick"
        >
            <!-- No subitems -->
            <NuxtLink
                v-if="!item.subItems"
                :to="item.path"
                class="font-bold w-full btn text-nowrap"
                :class="{
                    'text-blue-demegro': isActive && !depth,
                    'text-[16px] text-blue-demegro': isActive && depth,
                    'text-[16px] py-2 text-black-demegro': depth,
                }"
            >
                <span>
                    <font-awesome-icon
                        v-if="item.icon"
                        :icon="item.icon"
                        class="mr-1 mt-2 text-blue-demegro"
                    />
                    {{ item.label }}
                </span>
            </NuxtLink>

            <!-- With subitems -->
            <NuxtLink
                v-else
                :to="item.path"
                class="font-bold w-full btn text-nowrap"
                :class="{
                    'text-blue-demegro': isActive && !depth,
                    'text-[16px] text-blue-demegro': isActive && depth,
                    'text-[16px] py-2 text-black-demegro': depth,
                }"
            >
                <span>{{ item.label }}</span>
                <font-awesome-icon
                    v-if="!depth"
                    :icon="['fas', 'chevron-down']"
                    :class="{ 'transform rotate-180': isOpen }"
                    class="ml-3 mt-2 text-xs stroke-3"
                />
            </NuxtLink>

            <!-- Dropdown content -->
            <Transition>
                <div
                    v-if="item.subItems && isOpen"
                    class="absolute rounded-xl px-2.5 shadow-2xl bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-20"
                    :class="{
                        'left-0 top-full mt-[54px]': depth === 0,
                        'left-full top-0': depth !== 0,
                    }"
                    @mouseenter="mouseOver"
                    @mouseleave="mouseLeave"
                >
                    <template v-if="loading">
                        <NavMenubarSubitemSkeleton />
                    </template>
                    <template v-else>
                        <template v-for="(subItem, index) in item.subItems" :key="index">
                            <NavMenubarSubitem :item="subItem" :depth="depth + 1" />
                            <div
                                v-if="index < item.subItems.length - 1"
                                class="h-px bg-gray-demegro-light dark:bg-dark-gray-demegro"
                            />
                        </template>
                    </template>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script setup lang="ts">
interface MenuItem {
    path: string
    label: string
    subItems?: MenuItem[]
}

const props = defineProps<{
    item: MenuItem
    depth?: number
    loading?: boolean
}>()

const emit = defineEmits<{
    handleClick: []
}>()

const route = useRoute()
const isActive = computed(() => {
    return route.path.startsWith(props.item.path) && (props.item.path !== '/' || route.path === '/')
})

const isOpen = ref(false)
const isMouseOver = ref(false)
const closeTimer = ref<ReturnType<typeof setTimeout> | null>(null)

function handleClick() {
    emit('handleClick')
    isOpen.value = false
}

function mouseOver() {
    isOpen.value = true
    isMouseOver.value = true
}

function mouseLeave() {
    isMouseOver.value = false

    // Child always closes
    if (props.depth !== 0) {
        isOpen.value = false
        return
    }

    // Timer for main submenu
    setTimeout(() => {
        if (!isMouseOver.value) {
            isOpen.value = false
        }
    }, 500)
}
</script>
