<template>
    <nav>
        <div class="flex items-center justify-between h-16 flex-wrap">
            <div class="flex items-center">
                <div class="flex items-baseline space-x-20">
                    <NavMenubarSubitem
                        v-for="(item, index) in items"
                        :key="index"
                        :item="item"
                        :depth="0"
                        :loading="chaptersLoading && item.id === 1"
                    />
                </div>
            </div>
            <div class="w-[317px] h-[46px]">
                <NavSearchbar />
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
import type { MenuItem } from '~/types/application'

defineProps<{
    items: MenuItem[]
}>()

const chaptersLoading = toRef(useChapterStore(), 'loading')
</script>
