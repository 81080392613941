t
<template>
    <div class="relative">
        <NuxtLink
            to="/winkelwagen"
            class="btn border px-2.5 py-1.5 rounded-[15px]"
            :class="{
                'bg-cyan-demegro-light border-cyan-demegro text-blue-demegro': !cartStore.isEmpty,
                'border-gray-demegro': cartStore.isEmpty,
            }"
            @mouseenter="handleMouseEnter"
            @mouseleave="handleMouseLeave"
        >
            <font-awesome-icon :icon="['fas', 'cart-shopping']" class="text-base mr-[18px]" />
            <span class="mr-1">{{ cartStore.totalAmount || 0 }}</span>
        </NuxtLink>

        <!-- Cart Preview -->
        <Transition
            enter-active-class="transition ease-out duration-50"
            enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-50"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1"
        >
            <CartButtonPreview
                v-if="!loading && showPreview && !cartStore.isEmpty"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
                @close="showPreview = false"
            />
        </Transition>
    </div>
</template>

<script setup lang="ts">
const showPreview = ref(false)
const isMouseOver = ref(false)
const loading = ref(false)

function handleMouseEnter() {
    isMouseOver.value = true
    showPreview.value = true
}

function handleMouseLeave() {
    isMouseOver.value = false

    // Add delay before closing
    setTimeout(() => {
        if (!isMouseOver.value) {
            showPreview.value = false
        }
    }, 350)
}

const cartStore = useCartStore()
</script>
