<template>
    <footer
        class="py-8 bottom-0 w-full sm:static sm:mt-auto dark:text-gray-demegro-light transition-colors duration-300"
        role="contentinfo"
        aria-label="Site footer"
    >
        <div class="container mx-auto">
            <h2 class="text-[21px] font-bold mb-4">
                Producten
            </h2>

            <div class="grid grid-cols-5 gap-3">
                <template v-if="!loading">
                    <div v-for="chapter in chapters" :key="chapter.id">
                        <NuxtLink
                            :to="chapter.slug"
                            class="text-sm hover:text-blue-demegro dark:hover:text-dark-blue-demegro transition-colors"
                            :aria-label="`Navigate to ${chapter.title}`"
                        >
                            {{ chapter.title }}
                        </NuxtLink>
                    </div>
                </template>
                <div v-else class="col-span-5 flex justify-center items-center">
                    <font-awesome-icon
                        :icon="['fas', 'circle-notch']"
                        class="animate-spin"
                        aria-label="Loading chapters"
                    />
                </div>
            </div>
        </div>

        <div class="container mx-auto my-8">
            <hr class="border-t border-white dark:border-gray-demegro-light">
        </div>

        <div class="container mx-auto mt-8">
            <div class="grid grid-cols-5 gap-3 text-sm">
                <div class="col-span-2" />
                <div>
                    <p class="text-[21px] font-black mb-4">
                        Vragen?
                    </p>
                    <div class="space-y-2 transition-colors">
                        <NuxtLink to="/contact" class="block hover:text-blue-demegro dark:hover:text-dark-blue-demegro">
                            Contact
                        </NuxtLink>
                        <NuxtLink to="/faq" class="block hover:text-blue-demegro dark:hover:text-dark-blue-demegro">
                            FAQ
                        </NuxtLink>
                        <NuxtLink
                            to="/algemene-voorwaarden"
                            class="block hover:text-blue-demegro dark:hover:text-dark-blue-demegro"
                        >
                            Algemene voorwaarden
                        </NuxtLink>
                    </div>
                </div>
                <div>
                    <p class="text-[21px] font-black mb-4">
                        Over Ons
                    </p>
                    <div class="space-y-2 transition-colors">
                        <NuxtLink to="/over-ons" class="block hover:text-blue-demegro dark:hover:text-dark-blue-demegro">
                            Over ons
                        </NuxtLink>
                        <NuxtLink to="/vacatures" class="block hover:text-blue-demegro dark:hover:text-dark-blue-demegro">
                            Vacatures
                        </NuxtLink>
                    </div>
                </div>
                <div>
                    <p class="text-[21px] font-black mb-4">
                        Contact
                    </p>
                    <div class="row-span-3">
                        <p>Kovel 3</p>
                        <p>5431 ST Cuijk</p>
                        <p>Nederland</p>
                        <p>info@demegro.nl</p>
                        <p>0485 - 318 570</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
const chapters = toRef(useChapterStore(), 'chapters')
const loading = toRef(useChapterStore(), 'loading')
</script>
