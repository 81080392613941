<template #container="{ closeCallback }">
    <div class="flex flex-col px-8 py-8 gap-6 rounded-2xl bg-slate-50 drop-shadow-lg">
        <div class="inline-flex flex-col gap-2">
            <label for="username" class="">E-mailadres</label>
            <input
                id="username"
                v-model="email"
                class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition duration-200"
                type="email"
                required
            >
            </input>
        </div>
        <div class="inline-flex flex-col gap-2">
            <label for="password" class="">Wachtwoord</label>
            <input
                id="password"
                v-model="password"
                class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition duration-200"
                type="password"
                required
                @keyup.enter="handleSubmit(closeCallback)"
            >
            </input>
        </div>

        <div class="flex items-center justify-center gap-4">
            <button
                label="Cancel"
                class="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
                @click="closeCallback"
            />
            <button
                class="px-4 py-2 bg-blue-demegro text-white rounded-lg hover:bg-dark-blue-demegro transition-colors duration-200 shadow-md hover:shadow-lg relative"
                @click="handleSubmit(closeCallback)"
            >
                Inloggen
                <div v-if="loading" class="flex items-center justify-center">
                    <font-awesome-icon :icon="['fas', 'spinner-third']" class="fa-spin text-2xl" />
                </div>
            </button>
        </div>

        <TransitionGroup name="fade">
            <div v-if="errorMessage" key="error" class="mt-4 p-3 rounded-md bg-red-100 border border-red-300">
                <p class="text-red-700 text-sm">
                    {{ errorMessage }}
                </p>
            </div>
        </TransitionGroup>
    </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['login-success'])

const { signIn } = useAuth()

const email = ref('')
const password = ref('')
const error = ref<null | { status: number, message: string }>(null)
const loading = ref(false)

const route = useRoute()

const { getErrorFallback } = useError()

const errorMessage = computed(() => {
    if (!error.value)
        return null

    switch (error.value.status) {
        case 400:
            return getErrorFallback('login_failed')
        case 401:
            return getErrorFallback('invalid_credentials')
        default:
            return getErrorFallback('error')
    }
})

async function handleSubmit(closeCallback: () => void) {
    loading.value = true
    error.value = null

    try {
        const credentials = {
            email: email.value,
            password: password.value,
        }

        // Get redirect URL from query parameters
        const params = new URLSearchParams(window.location.search)
        const redirectUrl = params.get('redirect') || route.path

        const response = await signIn(credentials, {
            redirect: false,
            callbackUrl: redirectUrl,
        })

        if (response?.error) {
            error.value = { status: response.status ?? 500, message: response.error }
        }
        else {
            // Broadcast login to other tabs
            useBroadcast().broadcastLogin()

            emit('login-success')
            closeCallback()

            // Navigate to the redirect URL
            await navigateTo(redirectUrl)
        }
    }
    catch (err: any) {
        error.value = { status: err.status ?? 500, message: err.message ?? 'Unknown error' }
    }
    finally {
        loading.value = false
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
