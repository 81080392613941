<template>
    <div class="relative w-full">
        <!-- Search Input Group -->
        <div class="flex h-10">
            <input
                v-model="input"
                placeholder="Zoek door alle producten"
                class="pl-4 content-center h-full w-full rounded-l-md bg-white ring-1 ring-[#e7edef]"
                :class="{ 'ring-2 ring-blue-demegro': loading }"
                aria-label="Zoek producten"
                @keydown.enter="handleRawSearch"
                @click="handleSearchSuggestions"
            >
            <button
                class="bg-blue-demegro rounded-r-md p-3 text-white ring-1 ring-blue-demegro"
                aria-label="Zoeken"
                @click="handleSearchSuggestions"
            >
                <font-awesome-icon :icon="['faS', 'magnifying-glass']" class="text-[21px]" />
            </button>
        </div>

        <!-- Search Results Panel -->
        <div
            v-if="searchDropdownVisible"
            ref="searchDropdown"
            class="absolute top-full right-0 w-[600px] mt-2 max-h-[80vh] overflow-y-auto bg-white rounded-md shadow-lg ring-1 ring-gray-200 z-50"
        >
            <!-- Loading State -->
            <div v-if="loading" class="flex justify-center items-center p-4">
                <font-awesome-icon :icon="['faS', 'circle-notch']" class="animate-spin text-2xl text-blue-demegro" />
            </div>

            <!-- Results List -->
            <ul v-else-if="response" class="divide-y divide-gray-200">
                <li v-for="(producttype, index) in response" :key="index" class="group">
                    <!-- Producttype navigation link -->
                    <div class="flex items-center pl-3">
                        <div
                            class="flex items-center flex-grow cursor-pointer hover:bg-gray-50 transition-colors"
                            @click="handleProducttypeSearch(response[index])"
                        >
                            <div class="flex-shrink-0 w-12 h-12 mr-4">
                                <DPicture :src="producttype.image?.src" />
                            </div>
                            <div class="p-3 text-gray-900">
                                <span class="font-medium">{{ producttype.name }}</span>
                                <span class="text-sm text-gray-500 ml-2">({{ producttype.count }} artikelen)</span>
                            </div>
                        </div>

                        <!-- Expand/Collapse Button -->
                        <button
                            class="p-3 text-gray-400 hover:text-gray-600"
                            :aria-expanded="expandedTypes.has(producttype.id)"
                            :aria-label="expandedTypes.has(producttype.id) ? 'Collapse' : 'Expand'"
                            @click="toggleGroup(producttype)"
                        >
                            <font-awesome-icon
                                :icon="['faS', expandedTypes.has(producttype.id) ? 'chevron-up' : 'chevron-down']"
                                class="w-5 h-5"
                            />
                        </button>
                    </div>

                    <!-- Articles List -->
                    <div
                        v-if="expandedTypes.has(producttype.id) && producttype.articles"
                        class="border-t border-gray-100"
                    >
                        <ul class="divide-y divide-gray-100">
                            <li v-for="article in producttype.articles" :key="article.id">
                                <NuxtLink
                                    :to="article.slug"
                                    class="block p-3 hover:bg-gray-50 transition-colors"
                                    @click="resetSearch"
                                >
                                    <div class="flex items-center">
                                        <div v-if="article.main_image" class="flex-shrink-0 w-12 h-12 mr-4">
                                            <DPicture :src="article.main_image.src" class="" />
                                        </div>
                                        <div class="flex-grow min-w-0">
                                            <h4 class="text-sm font-medium text-gray-900 truncate">
                                                {{ article.title }}
                                            </h4>
                                            <p v-if="article.manufacturer_name" class="text-sm text-gray-500">
                                                {{ article.manufacturer_name }}
                                            </p>
                                        </div>
                                    </div>
                                </NuxtLink>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

            <div v-else-if="status === 'success'" class="p-4 text-center text-gray-600">
                Geen resultaten gevonden
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
// Types
import { onClickOutside, refDebounced } from '@vueuse/core'
import type { SearchResult } from '~/types/application'

// State
const input = ref('')
const searchQuery = refDebounced(input, 600)
const expandedTypes = ref<Set<number>>(new Set())
const searchDropdownVisible = ref(false)
const searchStore = useSearchStore()

// Data Fetching
const {
    data: response,
    refresh,
    status,
    clear,
} = useFetch<SearchResult[]>('/api/articles/searchsuggestion', {
    method: 'POST',
    body: {
        q: searchQuery,
    },
    immediate: false,
    watch: false,
})

// Computed
const loading = computed(() => status.value === 'pending')

// Methods
function toggleGroup(producttype: SearchResult) {
    if (expandedTypes.value.has(producttype.id)) {
        expandedTypes.value.delete(producttype.id)
    }
    else {
        expandedTypes.value.add(producttype.id)
    }
}

// This function sets the Producttype and its articles in the searchStore so we can open it in the search result page.
function handleProducttypeSearch(searchResult: SearchResult) {
    searchStore.clear()
    searchStore.setSearch('producttype', searchResult.name, searchResult.articles)

    resetSearch()

    navigateTo({
        path: `/zoeken/producttype`,
        query: { q: searchStore.currentSearchQuery },
    })
}

// Perform a raw search based on the current input
function handleRawSearch() {
    searchStore.clear()
    searchStore.setSearch('query', input.value, null)
    resetSearch()

    navigateTo({
        path: '/zoeken/product',
        query: { q: searchStore.currentSearchQuery },
    })
}

// Open the dropdown and show searchsuggestions
function handleSearchSuggestions() {
    if (searchQuery.value && !searchDropdownVisible.value) {
        refresh()
        dropdownOpen()
    }
}

function resetSearch() {
    clearNuxtData('searchPage')
    clear()
    input.value = ''
    expandedTypes.value = []
    dropdownClose()
}

function dropdownClose() {
    searchDropdownVisible.value = false
}

function dropdownOpen() {
    searchDropdownVisible.value = true
}

// Watch for the debounced searchQuery change so we can provide search suggestions
// Manual control of fetch lifecycle to prevent unnecessary requests
// Only clears when data exists, only fetches when query changes
watchEffect(
    () => {
        const query = searchQuery.value?.trim()

        if (!query) {
            if (response.value) {
                clear()
                dropdownClose()
            }
        }
        else {
            refresh()
            dropdownOpen()
        }
    },
    { flush: 'post' }, // Ensure state updates are complete before running
)

const dropdown = useTemplateRef('searchDropdown')
onClickOutside(dropdown, () => {
    dropdownClose()
})
</script>
