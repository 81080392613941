<template>
    <div class="min-h-screen bg-white text-black dark:bg-gray-800 dark:text-white transition-colors duration-300">
        <!-- <ErrorPage v-if="error" :error="error" /> -->
        <div class="flex flex-col min-h-screen">
            <header class="dark:bg-slate-700">
                <div class="w-full mx-auto p-4 xl:p-0 max-w-screen-xl">
                    <NavMenu />
                </div>
            </header>
            <main class="flex-grow pb-24 bg-slate-50 dark:bg-slate-700 overflow-x-hidden">
                <div class="w-full mx-auto p-4 xl:p-0 max-w-screen-xl">
                    <slot />
                </div>
            </main>
            <footer class="bg-blue-demegro text-white dark:bg-dark-blue-demegro">
                <div class="w-full mx-auto p-4 xl:p-0 max-w-screen-xl">
                    <NavFooter />
                </div>
            </footer>
        </div>
    </div>
</template>

<script setup lang="ts">
onBeforeMount(() => {
    // initialize the chapter store to fetch the chapters on load
    useChapterStore()
})
</script>

<style scoped>
.flex-grow {
    flex-grow: 1;
    overflow-y: auto;
}
</style>
